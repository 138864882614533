import { errorPageData } from "@/utils/404";
import dynamic from "next/dynamic";
import { STATIC_CDN_LOTTIE } from "@/config/constants";
import LinkWrapper from "@/components/LinkWrapper";
import FontAwesomeSvg from "@/components/SVG/Fontawesome";

const DotLottiePlayer = dynamic(() => import("@dotlottie/react-player").then((mod) => mod.DotLottiePlayer));

export interface IPageLinkItem {
  text: string,
  icon: string,
  url: string
}

const Page404 = (props: { data: IPageLinkItem[] }) => {
  return (
    <>
      <div className="body-content">
        <div className="max-h-screen-1/2 aspect-video mx-auto">
          <DotLottiePlayer
            src={`${STATIC_CDN_LOTTIE}/404.lottie`}
            autoplay
            loop
          />
        </div>
        <section>
          <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-2 justify-center items-center">
              <span className="text-xl">Hello? Page? Kahaan ho bhai?</span>
              <span className="text-lg font-medium text-center py-2 sm:text-2xl">Gumshuda page? Koi na, humare top stops neeche hai!</span>
            </div>
            <div className="grid sm:grid-cols-3 gap-2">
              {
                props.data.map((item: IPageLinkItem, index: number) => (
                  <div className="flex flex-col !p-3 border-b-2 sm:border-none" key={index}>
                    <LinkWrapper href={item.url} className="h-full link hover:!no-underline flex flex-col gap-3 justify-center items-center"><span><FontAwesomeSvg name={item.icon} className="text-3xl text-accent fill-accent" /></span><span className="!font-normal text-body">{item.text}</span></LinkWrapper>
                  </div>
                ))
              }
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export const getStaticProps = () => {
  return {
    props: {
      data: errorPageData,
    },
  };
};

export default Page404;